:root {
  --beige-text: #847976;
  --beige-dark: #776d6a;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  max-width: 100%;
  font-family: "Helvetica";
}


.full-height-container {
  height: 94vh;
}

.home-background {
  background-image: url("assets/scanhintergrund.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-header {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 22px;
  alignment: left;
  color: var(--beige-text);
}


.text-card-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  text-align: left;
  color: var(--beige-dark);
  font-size: .9rem;
  /*width: 100vh;*/
}

.text-card-container th {
  padding-top: 15px;
  padding-bottom: 8px;
  color: var(--beige-text);
}

/*
PORTFOLIO
*/

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.work-box:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding-top: 10px;
  text-align: left;
  color: var(--beige-dark);
}

.work-content, a:link {
  text-decoration: none;
}

.work-content .w-more {
  font-size: .9rem;
}

.work-content .w-more .w-ctegory {
  /*color: #0078ff;*/
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #0078ff;
  float: left;

}

.work-content .w-like a {
  color: #0078ff;
}

.work-content .w-like .num-like {
  font-size: .7rem;
}

.w-title {
  font-size: 1.2rem;
  text-decoration: none;
}

.instagram {
  height: 3.5vh;
  padding-right: 30px;
}