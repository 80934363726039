.nav-bar-container {
  height: 6vh;
  width: 100%;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 19px;

}

.nav-title-container {
  flex: 1;
  align-items: flex-end;
  text-align: left;
  padding-left: 30px;
  font-weight: bolder;
  font-size: 25px;
  color: var(--beige-text);


}

.nav-link-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar-link {
  text-decoration: none;
  /*font-family: "Darker Grotesque", sans-serif;*/
  font-weight: 300;
  /*height: 100px;*/
  /*width: 100px;*/
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*color: #eadeda;*/
  color: var(--beige-text);
  background-position: center;
  transition: background 0.6s;
}

.nav-bar-link:hover {
  opacity: .6;
  cursor: pointer;
  text-decoration: underline;
}

.nav-bar-link:active {
  background-size: 100%;
  transition: background 1s;
}

.nav-bar-link-active {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2829;
  color: #44a1a0;
}

.nav-bar-active-link:hover {
  cursor: pointer;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:active {
  background-size: 100%;
  transition: background 1s;
}

@media screen and (max-width: 786px) {
  .nav-bar-container {
    width: 100%;
  }

  .logo-image {
    margin-left: 0px;
  }

  .nav-bar-link,
  .nav-bar-link-active {
    font-weight: 400;
    font-size: 16px;
    width: 5rem;
  }
}


