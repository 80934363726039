/*!* .home-container {*/
/*	display: grid;*/
/*	grid-template-columns: */
/*        [viewport-start] minmax(1em, 1fr)*/
/*        [container-start] minmax(0, 30em) [container-end]*/
/*        minmax(1em, 1fr) [viewport-end];*/
/*	justify-items: center;	*/
/*} *!*/

.home-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 375px;
  width: 100%;
  border-radius: 10px;
}

.home-card-container {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  text-align: center;
}

.self-portrait {
  display: flex;
  flex: 1;
  text-align: left;
  margin-left: auto;
  margin-right: 2rem;
  height: 285px;
}

.home-column-2-container {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  align-items: space-evenly;
  height: 90%;
}

.home-heading {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  font-family: var(--heading);
  font-size: 50px;
  font-weight: 600;
  color: var(--white);
}

.home-body {
  display: flex;
  flex-wrap: nowrap;
  font-family: var(--copy);
  text-align: center;
  font-size: 30px;
  color: var(--white);
  margin: auto;
  margin-top: 10px;
}

.row-home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  text-align: center;
}

.card-button-home {
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  border: 2px solid var(--teal);
  font-family: var(--copy);
  text-decoration: none;
  text-transform: uppercase;
  /* margin-right: 20px; */
  width: 170px;
  height: 40px;
  /* margin-top: 10px; */
  background-color: var(--opaque);
  color: var(--teal);
  font-size: 20px;
  transition: 0.6s;
}

.card-button-home:hover {
  cursor: pointer;
  transition: 0.5s;
  opacity: 0.5;
}

.link-button:hover {
  cursor: pointer;
  transition: 0.5s;
  opacity: 0.5;
}

.home-icon {
  height: 35px;
  margin-right: 1em;
}

.hi-3 {
  height: 49px;
}

/* @media screen and (max-width: 359px) {
	.home-body {
		font-size: 12px;
	}
} */

@media screen and (max-width: 750px) {
  .home-card {
    height: 250px;
  }

  .self-portrait {
    margin-right: 15px;
  }

  .home-heading {
    font-size: 22px;
  }

  .home-body {
    font-size: 13px;
  }

  .self-portrait {
    height: 165px;
  }

  .row-home {
    margin-top: 5px;
  }
  .card-button-home {
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border: 1.5px solid var(--teal);
    font-family: var(--copy);
    text-decoration: none;
    text-transform: uppercase;
    /* margin-right: 20px; */
    width: 80px;
    height: 25px;
    margin-top: 10px;
    font-size: 13px;
  }

  /* #cbh-first-child {
		margin-right: 1em;
	} */
}
